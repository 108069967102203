<template>
  <div class="modal">
    <div class="modal-body the-box content pad-1" :class="{ partners: type === 'partners', iframe }">
      <div @click="$emit('modalAccepted', false)" class="close-modal">X</div>
      <slot></slot>
      <button v-if="type !== 'partners'" @click="$emit('modalAccepted', true)" class="button button--main full">
        {{ btnText ? btnText : 'Continue' }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Modal',
  props: ['btnText', 'type', 'iframe'],
};
</script>

<style lang="scss">
  .modal {
    position: fixed;
    padding: .5rem;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, .5);
    z-index: 9999;
    font-size: 0.85em;
  }
  .modal-body {
    position: relative;
    max-width: 100%;
    width: 500px;
    &.partners {
      padding: 0;
      border-radius: 0;
    }
    &.iframe {
      width: auto;
    }
  }
  .close-modal {
    cursor: pointer;
    font-weight: 600;
    position: absolute;
    right: -.25rem;
    top: -.75rem;
    width: 20px;
    height: 20px;
    background-color: $font;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    color: #fff;
  }
</style>
