<template>
  <div class="nav" :class="{ active: activeNav }">
    <div class="nav__top">
      <div class="nav-logo">
        <SiteLogo @click="goHome" class="site-logo"/>
      </div>
      <div @click="activeNav = !activeNav" class="nav-burger" :class="{ active: activeNav }"><div></div><div></div></div>
    </div>
    <div class="nav-items">
      <ul class="nav-items__ul">
        <li class="nav-item">
          <router-link class="nav-link" :to="{ path:`/${$store.state.currentGeo}/contents/rules` }">
            <div class="nav-icon-box">
              <ClipboardLogo class="top-nav-menu-icon clipboard-icon" />
            </div>
            {{ $store.state.globalContent.trRules }}
          </router-link>
        </li>
        <li class="nav-item">
          <router-link class="nav-link" :to="{ path:`/${$store.state.currentGeo}/contents/prizes` }">
            <div class="nav-icon-box">
              <PrizesLogo class="top-nav-menu-icon prizes-icon" />
            </div>
            {{ $store.state.globalContent.trPrizes }}
          </router-link>
        </li>
        <li class="nav-item nav-item--geo-select">
          <div class="nav-icon-box">
            <img class="geo-flag" :src="$store.state.availableGeos[currentGeo].flag" alt="flag">
          </div>
          <div class="active-geo-name">
            <select class="input--hidden-select" v-model="selectedGeo">
              <option value="uk">United Kingdom</option>
              <option value="de">Deutschland</option>
              <option value="es">España</option>
              <option value="br">Brasil</option>
              <option value="pe">Perú</option>
              <option value="at">Österreich</option>
              <option value="hu">Magyarország</option>
              <option value="cl">Chile</option>
              <option value="ec">Ecuador</option>
            </select>
            <span>{{ $store.state.availableGeos[currentGeo].name }}</span>
          </div>
        </li>
        <li class="nav-item">
          <a @click.prevent="signOut" class="nav-link">
            <div class="nav-icon-box">
              <LogOutLogo class="top-nav-menu-icon log-out-icon" />
            </div>
            {{ $store.state.globalContent.trSignOut }}
          </a>
        </li>
      </ul>
      <div class="nav-items-additionals">
        <router-link :to="{ name: 'ContentPages', params: { geo: currentGeo, slug: 'terms'} }">
          {{ $store.state.globalContent.trTermsAndConditions }}
        </router-link>
        <router-link :to="{ name: 'ContentPages', params: { geo: currentGeo, slug: 'privacy'} }">
          {{ $store.state.globalContent.trPrivacy }}
        </router-link>
        <router-link :to="{ name: 'ContentPages', params: { geo: currentGeo, slug: 'cookies'} }">
          {{ $store.state.globalContent.trCookies }}
        </router-link>
        <a href="mailto:contact@predictions4u.com?subject=Prediction4U-Contact">{{ $store.state.globalContent.trContact }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from 'firebase/app';
import 'firebase/auth';
import SiteLogo from '@/assets/images/p4u-logo.svg';
import ClipboardLogo from '@/assets/images/clipboard.svg';
import PrizesLogo from '@/assets/images/prizes.svg';
import LogOutLogo from '@/assets/images/log-out.svg';

export default {
  name: 'TopNavigation',
  components: {
    SiteLogo,
    ClipboardLogo,
    PrizesLogo,
    LogOutLogo,
  },
  data() {
    return {
      activeNav: false,
      selectedGeo: null,
    };
  },
  methods: {
    async signOut() {
      firebase.auth().signOut().then(() => {
        this.$store.dispatch('setUser', {
          id: null,
          email: null,
          loggedIn: false,
        });
        this.$router.replace({ name: 'Login' });
      }).catch((error) => {
        console.log(error);
      });
    },
    goHome() {
      if (this.$store.state.user.loggedIn === false) return;
      if (this.$route.name === 'Selections') return;
      this.$router.push({
        name: 'Selections',
        params: {
          tournament: this.$store.state.globalContent.activeTournaments[0].slug,
          geo: this.$route.params.geo,
        },
      });
    },
  },
  computed: {
    currentGeo() {
      return this.$store.state.currentGeo;
    },
  },
  watch: {
    selectedGeo(newGeo, oldGeo) {
      if (oldGeo === null) return;
      this.$store.dispatch('reloadContent', newGeo);
      this.$router.replace({
        name: this.$route.name,
        params: {
          geo: newGeo,
        },
      });
    },
    $route() {
      this.activeNav = false;
    },
  },
  created() {
    this.selectedGeo = this.$store.state.currentGeo;
  },
};
</script>

<style lang="scss">
  .nav__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .nav-items {
    visibility: collapse;
    max-height: 0;
    padding: 0 1rem;
    opacity: 0;
    overflow: hidden;
    background: $main;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    transition: 0.1s ease-in-out all;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .nav {
    position: relative;
    z-index: 2;
    padding: 1rem;
    transition: 0.1s ease-in-out background;
    &.active {
      background: $dark-teal;
      .nav-items {
        max-height: 1000px;
        opacity: 1;
        padding: 1rem;
        visibility: visible;
        background: $dark-teal;
        box-shadow: 0px 10px 7px 1px rgba(0, 0, 0, .25);
      }
    }
  }
  .nav-burger {
    cursor: pointer;
    div {
      width: 40px;
      height: 4px;
      background: $secondary;
      border-radius: 4px;
      margin: .25rem 0;
      transition: 0.1s ease-in-out all;
    }
    &.active {
      div {
        width: 20px;
      }
      div:first-child {
        transform: rotate(45deg) translateX(5px);
      }
      div:last-child {
        transform: rotate(-45deg) translateX(6px);
      }
    }
  }
  .nav-items__ul {
    list-style-type: none;
  }
  .nav-item {
    padding: .25rem 0;
    &:not(:last-child) {
      margin-bottom: .5rem;
    }
    &.active {
      background: rgba(250, 248, 248, .15);
      border-radius: 10px;
    }
  }
  .nav-link {
    color: $secondary;
    text-decoration: none;
    font-size: 0.85em;
    font-weight: 600;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  .top-nav-menu-icon {
    height: 21px;
  }
  .site-logo {
    width: 200px;
    max-width: 100%;
    display: block;
    fill: #fff;
    cursor: pointer;
  }
  .log-out-icon {
    fill: #fff;
  }
  .nav-icon-box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45px;
    pointer-events: none;
  }
  .nav-items-additionals {
    margin-top: 1rem;
    line-height: 1;
    a {
      cursor: pointer;
      text-decoration: none;
      color: rgba(255, 255, 255, .5);
      display: inline-block;
      margin: 0.25rem;
      font-size: 12px;
    }
  }
  .nav-item--geo-select {
    display: flex;
    align-items: center;
  }
  .geo-flag {
    height: 21px;
    border-radius: 50%;
  }
  .active-geo-name {
    position: relative;
    span {
      color: $secondary;
      font-weight: 600;
      font-size: 0.85em;
    }
  }
  .input--hidden-select {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    outline: none !important;
  }
</style>
