<template>
  <div class="loader-overlay">
    <SiteLogo class="site-logo-loader"/>
    <div class="lds-dual-ring"></div>
  </div>
</template>

<script>
import SiteLogo from '@/assets/images/p4u-logo.svg';

export default {
  name: 'LoaderOverlay',
  components: {
    SiteLogo,
  },
};
</script>

<style lang="scss">
  .loader-overlay {
    position: fixed;
    background: $main;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99999;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .site-logo-loader {
    display: block;
    width: 200px;
    margin-bottom: 2rem;
    fill: $font;
  }
</style>
