<template>
  <div v-if="show" class="cookie-bar">
    <div class="cookies-content">
      {{ $store.state.globalContent.trCookieBar }}
      <span class="cookies-read-more">
        <router-link :to="{ name: 'ContentPages', params: { geo: $store.state.currentGeo, slug: 'cookies'} }">{{ $store.state.globalContent.trCookieReadMore }}</router-link>
      </span>
    </div>
    <button @click="dismissCookieBar" class="button button--small button--small-cookies">Ok</button>
  </div>
</template>

<script>
export default {
  name: 'CookieBar',
  data() {
    return {
      show: localStorage.getItem('cookieBar') === null,
    };
  },
  methods: {
    dismissCookieBar() {
      localStorage.setItem('cookieBar', true);
      this.show = false;
    },
  },
};
</script>

<style lang="scss">
  .cookie-bar {
    display: flex;
    align-items: center;
    position: sticky;
    bottom: 0;
    left: 0;
    background-color: $maldives;
    color: #fff;
    line-height: 1.25;
    padding: .25rem 1rem;
    font-size: 12px;
  }
  .cookies-content {
    margin-right: 1rem;
  }
  .cookies-read-more {
    a {
      font-weight: 600;
      color: #fff;
    }
  }
  .button--small-cookies {
    font-weight: 600;
  }
</style>
