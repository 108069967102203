import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import VueAxios from 'vue-axios';

Vue.use(Vuex);
Vue.use(VueAxios, axios);

export default new Vuex.Store({
  state: {
    currentGeo: null,
    availableGeos: {
      uk: {
        name: 'United Kingdom',
        flag: 'https://dk03q18yog11l.cloudfront.net/wp-content/uploads/2021/06/02120712/110-united-kingdom.jpg',
        locale: 'en-gb',
      },
      de: {
        name: 'Deutschland',
        flag: 'https://dk03q18yog11l.cloudfront.net/wp-content/uploads/2021/06/02120848/208-germany.jpg',
        locale: 'de-de',
      },
      es: {
        name: 'España',
        flag: 'https://dk03q18yog11l.cloudfront.net/wp-content/uploads/2021/06/02120900/230-spain.jpg',
        locale: 'es',
      },
      br: {
        name: 'Brasil',
        flag: 'https://dk03q18yog11l.cloudfront.net/wp-content/uploads/2021/06/02120554/022-brazil.jpg',
        locale: 'pt-br',
      },
      pe: {
        name: 'Perú',
        flag: 'https://dk03q18yog11l.cloudfront.net/wp-content/uploads/2021/06/02120837/189-peru.jpg',
        locale: 'es',
      },
      at: {
        name: 'Österreich',
        flag: 'https://dk03q18yog11l.cloudfront.net/wp-content/uploads/2021/06/02120634/072-austria.jpg',
        locale: 'de-at',
      },
      hu: {
        name: 'Magyarország',
        flag: 'https://dk03q18yog11l.cloudfront.net/wp-content/uploads/2021/06/02120849/210-hungary.jpg',
        locale: 'hu',
      },
      cl: {
        name: 'Chile',
        flag: 'https://dk03q18yog11l.cloudfront.net/wp-content/uploads/2021/06/02120850/212-chile.jpg',
        locale: 'es',
      },
      ec: {
        name: 'Ecuador',
        flag: 'https://dk03q18yog11l.cloudfront.net/wp-content/uploads/2021/06/02120851/214-ecuador.jpg',
        locale: 'es',
      },
    },
    appInitiated: false,
    activeTournament: null,
    user: {
      id: null,
      email: null,
      loggedIn: false,
    },
    globalContent: {
      activeTournaments: [],
      loginBanner: false,
      steps: [],
      trStepsHeading: '',
      trSignIn: '',
      trSocial: '',
      trEmail: '',
      trTerms: '',
      trNewsletter: '',
      trAge: '',
      trTermsShort: '',
      trOr: '',
      trRegister: '',
      trPassword: '',
      trEmailLabel: '',
      trRules: '',
      trPrizes: '',
      trSignOut: '',
      trTermsAndConditions: '',
      trPrivacy: '',
      trCookies: '',
      trContact: '',
      trLeaderboard: '',
      trOverview: '',
      trShare: '',
      trShareInvite: '',
      trTimerLabel: '',
      trDays: '',
      trHours: '',
      trMinutes: '',
      trBonusQBox: '',
      trSelectOne: '',
      trPoints: '',
      trSubmitBonusQ: '',
      trAnswersFeedback: '',
      trUniqueAnswers: '',
      trQuestion: '',
      trLinkCopied: '',
      trBonusQThankYou: '',
      trMakePredictions: '',
      trMatchStarted: '',
      trMatchFinished: '',
      trYourPrediction: '',
      trConfMessage1: '',
      trConfMessage2: '',
      trFinalScore: '',
      trRemainingMatches: '',
      trSubmitPicks: '',
      trAwait: '',
      trFinalButton: '',
      trPicksThankYouMessage: '',
      trPreLeaderboard: '',
      trForgottenPassword: '',
      trEnterEmail: '',
      trResetPasswordSuccess: '',
      trResetPassword: '',
      trCookieBar: '',
      trCookieReadMore: '',
      stepsOverlay: {},
      picksOverlay: {},
    },
  },
  mutations: {
    GET_GLOBAL_CONTENT(state, payload) {
      state.globalContent = payload.data;
      state.appInitiated = true;
    },
    SET_ACTIVE_TOURNAMENT(state, payload) {
      if (payload === 'firstAvailable') {
        const activeTournament = state.globalContent.activeTournaments[0];
        state.activeTournament = activeTournament.slug;
        return;
      }
      state.activeTournament = payload;
    },
    SET_USER(state, payload) {
      state.user = payload;
    },
    SET_CURRENT_GEO(state, geo) {
      state.currentGeo = geo;
    },
    RELOAD_CONTENT(state) {
      state.appInitiated = false;
      state.currentGeo = null;
    },
  },
  actions: {
    async getGlobalContent({ commit }, geo) {
      try {
        const { data } = await axios.get(`${process.env.VUE_APP_API}/options/v1/settings/${geo}`);
        commit('GET_GLOBAL_CONTENT', {
          data,
        });
      } catch (error) {
        console.log(error);
      }
    },
    setActiveTournament({ commit }, payload) {
      commit('SET_ACTIVE_TOURNAMENT', payload);
    },
    setUser({ commit }, payload) {
      commit('SET_USER', payload);
    },
    setCurrentGeo({ commit }, geo) {
      commit('SET_CURRENT_GEO', geo);
    },
    reloadContent({ commit }) {
      commit('RELOAD_CONTENT');
    },
  },
  getters: {
    getLoginContent(state) {
      return {
        loginBanner: state.globalContent.loginBanner,
        steps: state.globalContent.steps,
        trStepsHeading: state.globalContent.trStepsHeading,
        trSignInBig: state.globalContent.trSignInBig,
        trSignIn: state.globalContent.trSignIn,
        trSocial: state.globalContent.trSocial,
        trEmail: state.globalContent.trEmail,
        trTerms: state.globalContent.trTerms,
        trNewsletter: state.globalContent.trNewsletter,
        trAge: state.globalContent.trAge,
        trTermsShort: state.globalContent.trTermsShort,
        trOr: state.globalContent.trOr,
        trRegister: state.globalContent.trRegister,
        trPassword: state.globalContent.trPassword,
        trEmailLabel: state.globalContent.trEmailLabel,
        trForgottenPassword: state.globalContent.trForgottenPassword,
        trEnterEmail: state.globalContent.trEnterEmail,
        trResetPasswordSuccess: state.globalContent.trResetPasswordSuccess,
        trResetPassword: state.globalContent.trResetPassword,
      };
    },
    getBonusQuestionsContent(state) {
      return {
        trBonusQBox: state.globalContent.trBonusQBox,
        trSelectOne: state.globalContent.trSelectOne,
        trPoints: state.globalContent.trPoints,
        trSubmitBonusQ: state.globalContent.trSubmitBonusQ,
        trAnswersFeedback: state.globalContent.trAnswersFeedback,
        trUniqueAnswers: state.globalContent.trUniqueAnswers,
        trQuestion: state.globalContent.trQuestion,
      };
    },
    getGamesPageContent(state) {
      return {
        trMakePredictions: state.globalContent.trMakePredictions,
        trMatchStarted: state.globalContent.trMatchStarted,
        trMatchFinished: state.globalContent.trMatchFinished,
        trYourPrediction: state.globalContent.trYourPrediction,
        trConfMessage1: state.globalContent.trConfMessage1,
        trConfMessage2: state.globalContent.trConfMessage2,
        trFinalScore: state.globalContent.trFinalScore,
        trRemainingMatches: state.globalContent.trRemainingMatches,
        trSubmitPicks: state.globalContent.trSubmitPicks,
        trAwait: state.globalContent.trAwait,
        trFinalButton: state.globalContent.trFinalButton,
      };
    },
    getActiveTournaments(state) {
      return state.globalContent.activeTournaments;
    },
    getStepsOverlay(state) {
      return state.globalContent.stepsOverlay;
    },
    getPicksOverlay(state) {
      return state.globalContent.picksOverlay;
    },
  },
});
