<template>
  <div class="page-404 flex-1">
    404 Page Not Found
  </div>
</template>

<script>
export default {
  name: 'PageNotFound',
};
</script>

<style>
  .page-404 {
    background: #ffffff;
    padding: 4rem 2rem;
    height: 100%;
    text-align: center;
    font-weight: 600;
    font-size: 1.5rem;
  }
</style>
