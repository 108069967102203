<template>
  <div class="login-body flex-1">
    <div v-if="getLoginContent.loginBanner" class="login-banner">
      <img :src="getLoginContent.loginBanner" alt="login banner">
    </div>
    <div class="login-inner-body pad-1">
      <div v-if="getLoginContent.steps.length > 0" class="login-steps the-box content">
        <h4 class="steps-label">{{ getLoginContent.trStepsHeading }}</h4>
        <div class="steps-swiper-wrapper">
          <div class="steps-swipe" :style="{ width: stepsWidth }">
            <div v-for="(step, index) in getLoginContent.steps" :key="index" class="step-single">
              {{ step }}
            </div>
          </div>
        </div>
      </div>
      <div class="sign-in-wrapper the-box pad-1 content">
        <div class="sign-in-intro" v-html="getLoginContent.trSignInBig"></div>
        <div class="sign-in-tabs">
          <div class="button-group">
            <button @click="activeTab = 'social'" class="button" :class="{ active: activeTab === 'social' }">{{ getLoginContent.trSocial }}</button>
            <button @click="activeTab = 'regular'" class="button" :class="{ active: activeTab === 'regular' }">{{ getLoginContent.trEmail }}</button>
          </div>
          <div v-if="activeTab === 'social'" class="sing-in-tab">
            <div class="social-login-options" :class="{ disabled: !checked || !checked2 || !checked3 }">
              <button v-if="false" @click="facebookSignIn" class="button button--facebook">Facebook</button>
              <button @click="gmailSignIn" class="button button--gmail">
                <img src="@/assets/images/google-icon.png" alt="google sing in">
                <span>Google</span>
              </button>
            </div>
          </div>
          <form @submit.prevent="userSignIn">
            <div v-if="activeTab === 'regular'" class="sign-in-tab" :class="{ busy: submitting }">
              <div class="login-or-register">
                <span @click="loginOrRegister = 'login'" :class="{ active: loginOrRegister === 'login' }">{{ getLoginContent.trSignIn }}</span>
                {{ getLoginContent.trOr }}
                <span @click="loginOrRegister = 'register'" :class="{ active: loginOrRegister === 'register' }">{{ getLoginContent.trRegister }}</span>
              </div>
              <div class="regular-log-in">
                <div class="input-group">
                  <label>{{ getLoginContent.trEmail }}</label>
                  <input @focus="feedback = ''" class="input" type="email" v-model="user.email">
                  <p class="input-feedback">{{ getLoginContent.trEmailLabel }}</p>
                </div>
                <div class="input-group">
                  <label>{{ getLoginContent.trPassword }}</label>
                  <input @focus="feedback = ''" class="input" type="password" v-model="user.password">
                </div>
              </div>
              <div v-if="loginOrRegister === 'login'" class="password-reset">
                <a @click.prevent="resetPasswordModal = true">Forgotten Password?</a>
              </div>
              <div v-if="feedback" class="sign-in-feedback">
                {{ feedback }}
              </div>
            </div>
            <div v-if="activeTab === 'regular'" class="sign-in-submit">
              <div v-if="submitting" class="lds-dual-ring button-loading"></div>
              <button class="button button--main button--login full" :disabled="submitting">{{ loginOrRegister === 'login' ? getLoginContent.trSignIn : getLoginContent.trRegister }}</button>
            </div>
            <div v-if="loginOrRegister === 'register' || activeTab === 'social'" class="sing-in-checks content">
              <div class="sign-in-check">
                <div class="checbox-content">
                  <input type="checkbox" v-model="checked" :required="loginOrRegister === 'register'">
                  <router-link :to="{ name: 'ContentPages', params: { geo: this.$route.params.geo, slug: 'terms' } }">{{ getLoginContent.trTerms }}</router-link>
                </div>
                <div class="checbox-content">
                  <input type="checkbox" v-model="checked2" :required="loginOrRegister === 'register'"> {{ getLoginContent.trNewsletter }}
                </div>
                <div class="checbox-content">
                  <input type="checkbox" v-model="checked3" :required="loginOrRegister === 'register'"> {{ getLoginContent.trAge }}
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="ia"></div>
        <div class="comp-content content" v-html="getLoginContent.trTermsShort"></div>
      </div>
    </div>
    <transition name="fade">
      <Modal @modalAccepted="acceptModal($event)" v-if="resetPasswordModal" :btnText="getLoginContent.trResetPassword">
        <p>{{ getLoginContent.trEnterEmail }}</p>
        <div class="input-group input-group--modal">
          <label>{{ getLoginContent.trEmail }}</label>
          <input class="input" type="email" v-model="resetPasswordEmail">
        </div>
        <div v-if="resetFeedback" class="reset-feedback">{{ resetFeedback }}</div>
        <div v-if="resetPasswordLoader" class="lds-dual-ring rest-password-loader"></div>
      </Modal>
    </transition>
    <IntroModal/>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import firebase from 'firebase/app';
import 'firebase/auth';
import Modal from '@/components/Modal.vue';
import IntroModal from '@/components/IntroModal.vue';
import getParameterByName from '@/helpers/getParameterByName';

export default {
  name: 'Login',
  components: {
    Modal,
    IntroModal,
  },
  data() {
    return {
      activeTab: 'social',
      loginOrRegister: 'login',
      submitting: false,
      feedback: '',
      checked: true,
      checked2: true,
      checked3: true,
      user: {
        email: '',
        password: '',
      },
      shareId: false,
      resetPasswordEmail: '',
      resetPasswordModal: false,
      resetFeedback: '',
      resetPasswordLoader: false,
      resetSubmission: false,
    };
  },
  methods: {
    ...mapActions([
      'setUser',
    ]),
    userSignIn() {
      if (this.submitting) return;
      this.submitting = true;
      if (this.loginOrRegister === 'login') this.loginUser();
      if (this.loginOrRegister === 'register') this.registerUser();
    },
    letIn(authUser) {
      this.setUser({
        id: authUser.user.uid,
        email: authUser.user.email,
        loggedIn: true,
      });
      this.saveReferral(authUser.user.email, this.$route.params.geo);
      this.$router.replace({
        name: 'Selections',
        params: {
          tournament: this.$store.state.globalContent.activeTournaments[0].slug,
        },
      });
    },
    async registerUser() {
      try {
        const authUser = await firebase.auth().createUserWithEmailAndPassword(this.user.email, this.user.password);
        this.submitting = false;
        this.letIn(authUser);
        this.dataLayerPush('register');
      } catch (error) {
        this.submitting = false;
        this.feedback = error.message;
      }
    },
    async loginUser() {
      try {
        const authUser = await firebase.auth().signInWithEmailAndPassword(this.user.email, this.user.password);
        this.submitting = false;
        this.letIn(authUser);
        this.dataLayerPush('sign-in');
      } catch (error) {
        this.submitting = false;
        this.feedback = error.message;
      }
    },
    async gmailSignIn() {
      if (!this.checked || !this.checked2 || !this.checked3) return;
      const provider = new firebase.auth.GoogleAuthProvider();
      try {
        const authUser = await firebase.auth().signInWithPopup(provider);
        this.submitting = false;
        this.letIn(authUser);
        this.dataLayerPush('sign-in', true);
      } catch (error) {
        this.submitting = false;
        this.feedback = error.message;
      }
    },
    async facebookSignIn() {
      // if (!this.checked || !this.checked2 || !this.checked3) return;
      // const provider = new firebase.auth.FacebookAuthProvider();
      // try {
      //   const authUser = await firebase.auth().signInWithPopup(provider);
      //   this.submitting = false;
      //   this.letIn(authUser);
      // } catch (error) {
      //   this.submitting = false;
      //   this.feedback = error.message;
      // }
    },
    async resetPassword() {
      if (this.resetPasswordLoader) return;
      this.resetPasswordLoader = true;
      try {
        await firebase.auth().sendPasswordResetEmail(this.resetPasswordEmail);
        this.resetFeedback = this.getLoginContent.trResetPasswordSuccess;
        this.resetPasswordLoader = false;
        setTimeout(() => {
          this.resetFeedback = '';
          this.resetPasswordModal = false;
        }, 2000);
      } catch (error) {
        this.resetFeedback = error.message;
        this.resetPasswordLoader = false;
      }
    },
    acceptModal(event) {
      if (!event) {
        this.resetPasswordModal = false;
        return;
      }
      this.resetPassword();
    },
    async saveReferral(user, geo) {
      if (!this.shareId) return;
      try {
        const config = {
          headers: {
            'Content-Type': 'application/json',
          },
        };
        const { data } = await this.axios.post(`${process.env.VUE_APP_API}/referral/v1/save`, { shareid: this.shareId, user, geo }, config);
        console.log(data);
      } catch (error) {
        console.log(error);
      }
    },
    dataLayerPush(signInAction, social = false) {
      let authenticationMethod = 'email';
      if (social) {
        authenticationMethod = 'google';
      }
      const type = signInAction === 'register' ? 'register' : 'sign in';
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'login',
        Type: type,
        authenticationMethod,
      });
    },
    checkForShareid() {
      const shareid = getParameterByName('shareid');
      const sessionShareid = sessionStorage.getItem('shareid');
      if (shareid) this.shareId = shareid;
      if (shareid && sessionShareid === null) {
        sessionStorage.setItem('shareid', shareid);
      }
      if (!shareid && sessionShareid !== null) {
        this.shareId = sessionShareid;
      }
    },
  },
  computed: {
    ...mapGetters([
      'getLoginContent',
    ]),
    stepsWidth() {
      return `calc(${100 * this.getLoginContent.steps.length}% - ${6 * this.getLoginContent.steps.length}rem)`;
    },
  },
  created() {
    if (this.$store.state.user.loggedIn) {
      this.$router.replace({
        name: 'Selections',
        params: {
          tournament: this.$store.state.globalContent.activeTournaments[0].slug,
        },
      });
    }
    this.checkForShareid();
  },
};
</script>

<style lang="scss">
  .sign-in-tabs {
    margin-bottom: 1rem;
  }
  .login-inner-body {
    padding-bottom: 0;
  }
  .sign-in-tab {
    transition: 0.3s ease-in-out opacity;
    &.busy {
      pointer-events: none;
      opacity: 0.3;
    }
  }
  .sign-in-intro {
    margin-bottom: 1rem;
    font-size: 0.85em;
    h4 {
      margin-bottom: .5rem;
    }
  }
  .login-banner {
    img {
      display: block;
      width: 100%;
    }
  }
  .login-steps {
    padding: 1rem 0 1rem 1rem;
    margin-top: -3rem;
    position: relative;
    z-index: 1;
  }
  .steps-swipe {
    display: flex;
    counter-reset: step;
  }
  .step-single {
    position: relative;
    padding: 1rem 1rem 1rem 3rem;
    font-size: 0.85em;
    flex: 1;
    display: flex;
    align-items: center;
    &::before {
      counter-increment: step;
      content: counter(step);
      background: $maldives;
      color: $secondary;
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 50%;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 700;
    }
  }
  .steps-swiper-wrapper {
    font-size: 0.85em;
    overflow-x: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .sign-in-wrapper {
    margin: 1rem 0;
  }
  .social-login-options {
    display: flex;
    flex-direction: column;
    &.disabled {
      opacity: 0.3;
      .button {
        cursor: not-allowed;
      }
    }
  }
  .sing-in-tab {
    margin-top: 1rem;
  }
  .button--gmail {
    margin-top: .5rem;
  }
  .button--facebook {
    background: #007aff;
    color: $secondary;
    border-color: transparent;
  }
  .button--gmail {
    background: #DB4437;
    color: #fff;
    border-color: transparent;
    position: relative;
    min-height: calc(50px + .25rem);
    img {
      width: 50px;
      position: absolute;
      left: .125rem;
      top: 50%;
      transform: translateY(-50%);
      pointer-events: none;
    }
  }
  .sing-in-checks {
    font-size: 12px;
    margin-top: 1rem;
    p {
      margin-top: 1rem;
      color: $dark-teal;
    }
  }
  .regular-log-in {
    margin-top: 1rem;
  }
  .login-or-register {
    margin-top: 1rem;
    text-align: center;
    span {
      font-weight: 600;
      cursor: pointer;
      &.active {
        text-decoration: underline;
      }
    }
  }
  .sign-in-submit {
    position: relative;
    margin-top: 1rem;
  }
  .button--login[disabled] {
    color: transparent;
    cursor: not-allowed;
  }
  .sign-in-feedback {
    margin-top: 1rem;
    font-size: 0.85em;
    text-align: center;
    font-weight: 600;
  }
  .checbox-content {
    input {
      margin-right: .25rem;
    }
    &:not(:last-child) {
      margin-bottom: .25rem;
    }
  }
  .comp-content {
    margin-top: 1rem;
    font-size: 0.85em;
    background-color: $dark-teal;
    color: #fff;
    margin: 1rem -1rem -1rem;
    padding: 1rem;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .password-reset {
    margin-top: 1rem;
    font-size: 0.85em;
    text-align: center;
    a {
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .reset-feedback {
    margin-bottom: .5em;
  }
  .input-group.input-group--modal {
    margin-bottom: .5rem;
  }
  .lds-dual-ring.rest-password-loader {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, .5);
    border-radius: 10px;
    z-index: 1;
  }
</style>
