<template>
  <transition name="fade">
    <div v-if="modalActive && getStepsOverlay.count !== 0 && currentStep < getStepsOverlay.count + 1" class="intro-modal">
      <div v-for="(step, index) in getStepsOverlay.steps" :key="index" class="intro-modal-step the-box pad-1 content" :class="{ active: currentStep === index + 1 }">
        <div class="intro-modal-content">
          <div v-html="getStepsOverlay.steps[index].content"></div>
          <div v-if="getStepsOverlay.steps[index].tcs && step !== getStepsOverlay.count" class="modal-tcs">{{ getStepsOverlay.steps[index].tcs }}</div>
          <div v-if="step === getStepsOverlay.count" class="modal-tcs">
            {{ $store.state.globalContent.trCookieBar }}
            <router-link :to="{ name: 'ContentPages', params: { geo: $store.state.currentGeo, slug: 'cookies'} }">{{ $store.state.globalContent.trCookieReadMore }}</router-link>
          </div>
          <div class="next-step-cta">
            <button @click="nextStep"  class="button button--main full">{{ getStepsOverlay.steps[index].button }}</button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'IntroModal',
  data() {
    return {
      modalActive: false,
      currentStep: 1,
    };
  },
  methods: {
    nextStep() {
      this.currentStep += 1;
      if (this.currentStep === this.getStepsOverlay.count && localStorage.getItem('introModalSteps') === null) {
        localStorage.setItem('introModalSteps', true);
      }
    },
    initStepsModal() {
      const introModalSteps = localStorage.getItem('introModalSteps');
      if (introModalSteps === null && this.getStepsOverlay.count !== 0) {
        setTimeout(() => {
          this.modalActive = true;
        }, 1000);
      }
    },
  },
  computed: {
    ...mapGetters([
      'getStepsOverlay',
    ]),
  },
  mounted() {
    this.initStepsModal();
  },
};
</script>

<style lang="scss">
  .intro-modal {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .65);
    z-index: 9999;
  }
  .intro-modal-step {
    background-color: $font;
    color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    max-width: calc(100% - 2rem);
    transition: 0.5s ease-in-out all;
    max-height: calc(100vh - 2rem);
    overflow-x: auto;
    &:not(.active) {
      opacity: 0;
      pointer-events: none;
      z-index: -1;
    }
    span {
      font-weight: 600;
    }
    h1, h2, h3, h4, h5, h6 {
      color: $maldives;
    }
    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
      counter-reset: step;
    }
    li {
      position: relative;
      padding-left: 3rem;
      &:not(:last-child) {
        margin-bottom: 1rem;
      }
      &::after {
        counter-increment: step;
        content: counter(step);
        position: absolute;
        left: 0;
        top: 0;
        height: 2rem;
        width: 2rem;
        line-height: 1;
        background: $maldives;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.25em;
        font-weight: 600;
        border-radius: 50%;
      }
    }
  }
  .next-step-cta {
    margin-top: 1rem;
  }
  .modal-tcs {
    color: #8a8a8a;
    line-height: 1.25;
    font-size: 12px;
    margin-top: 1rem;
    a {
      color: #8a8a8a;
      text-decoration: none;
    }
  }
</style>
