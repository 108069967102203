// import global css
import 'normalize.css';
import './assets/css/main.scss';

import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import firebase from 'firebase/app';
import 'firebase/auth';
import router from './router';
import store from './store';
import './registerServiceWorker';
import App from './App.vue';

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: 'predictions4u-fe68f',
  storageBucket: 'predictions4u-fe68f.appspot.com',
  messagingSenderId: '1014447348961',
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
};

firebase.initializeApp(firebaseConfig);
// firebase.auth().useDeviceLanguage();

Vue.config.productionTip = false;

Vue.use(VueAxios, axios);

const moment = require('moment');

Vue.use(require('vue-moment'), {
  moment,
});

let app;

// Because firebase.auth().currentUser is not ready on app refresh
firebase.auth().onAuthStateChanged((authUser) => {
  if (!app) {
    if (authUser) {
      store.dispatch('setUser', {
        id: authUser.uid,
        email: authUser.email,
        loggedIn: true,
      });
    }
    app = new Vue({
      router,
      store,
      render: (h) => h(App),
    }).$mount('#app');
  }
});
