<template>
  <div class="bottom-nav">
    <transition name="fade">
      <div v-if="feedback" class="social-share-message">
        {{ $store.state.globalContent.trLinkCopied }}
      </div>
    </transition>
    <div class="bottom-nav-item">
      <input ref="share-input-2" class="hidden-share-input" type="text" :value="`${this.shareDomain}/${this.$route.params.geo}/login/?shareid=${this.$store.state.user.id}`">
      <a @click.prevent="share" class="bottom-nav-link">
        {{ $store.state.globalContent.trShare }}
      </a>
      <ShareLogo class="bottom-nav-icon"/>
    </div>
    <div class="bottom-nav-item">
      <router-link
      class="bottom-nav-link"
      :to="{ path: `/${$store.state.currentGeo}/tournament/${$store.state.activeTournament}` }">
      {{ $store.state.globalContent.trOverview }}
      </router-link>
      <OverviewLogo class="bottom-nav-icon"/>
    </div>
    <div class="bottom-nav-item">
      <router-link
      class="bottom-nav-link"
      :to="{ path: `/${$store.state.currentGeo}/tournament/${$store.state.activeTournament}/leaderboard` }">
      {{ $store.state.globalContent.trLeaderboard }}
      </router-link>
      <LeaderboardLogo class="bottom-nav-icon"/>
    </div>
  </div>
</template>

<script>
import LeaderboardLogo from '@/assets/images/leaderboard.svg';
import OverviewLogo from '@/assets/images/overview.svg';
import ShareLogo from '@/assets/images/share-alt.svg';

export default {
  name: 'BottomNavigation',
  components: {
    LeaderboardLogo,
    OverviewLogo,
    ShareLogo,
  },
  data() {
    return {
      feedback: '',
    };
  },
  methods: {
    async share() {
      const shareData = {
        title: 'Predictions4U',
        text: 'EURO 2020 & Copa America 2021 Football! Predict for free and you may Win great Prizes!',
        url: `${this.shareDomain}/${this.$route.params.geo}/login/?shareid=${this.$store.state.user.id}`,
      };
      try {
        await navigator.share(shareData);
      } catch {
        this.copyLink();
      }
    },
    copyLink() {
      this.$refs['share-input-2'].select();
      document.execCommand('copy');
      this.feedback = this.$store.state.globalContent.trLinkCopied;
      setTimeout(() => {
        this.feedback = '';
      }, 3000);
    },
  },
  computed: {
    shareDomain() {
      return process.env.VUE_APP_FRONT_DOMAIN;
    },
  },
};
</script>

<style lang="scss">
  .bottom-nav {
    display: flex;
    padding: 1.25rem .5rem 1.5rem;
    font-size: 0.85em;
    width: 500px;
    max-width: 100%;
    background: $dark-teal;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    position: sticky;
    bottom: 0;
    text-align: center;
    z-index: 2;
  }
  .bottom-nav-icon {
    display: block;
    margin: 0 auto;
    height: 14px;
    order: 1;
  }
  .bottom-nav-item {
    flex: 1;
    padding: 0 .25rem;
    position: relative;
    display: flex;
    flex-direction: column;
  }
  .bottom-nav-link {
    order: 2;
    line-height: 1;
    display: block;
    text-decoration: none;
    color: $secondary;
    font-size: 0.85em;
    font-weight: 600;
    margin-top: .5rem;
    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
    }
    &.router-link-exact-active {
      color: #02c6e0;
    }
  }
  .social-share-message {
    position: absolute;
    top: -2rem;
    left: 50%;
    background: #01bdd8;
    width: calc(100% - 1rem);
    transform: translateX(-50%);
    color: #fff;
    padding: .25rem .5rem;
    border-radius: 5px;
    font-weight: 600;
  }
</style>
