<template>
  <div id="app">
    <transition name="fade">
      <LoaderOverlay v-if="$store.state.appInitiated === false" />
    </transition>
    <div v-if="$store.state.appInitiated" class="app-body" :class="{ 'logged-in': $store.state.user.loggedIn }">
      <div v-if="$store.state.user.loggedIn" class="top-bg-box">
        <img v-show="$route.params.tournament !== undefined && $route.params.tournament.includes('euro')" src="@/assets/images/euro-2020-banner.jpg" alt="">
        <img v-show="$route.params.tournament !== undefined && $route.params.tournament.includes('copa')" src="@/assets/images/copa-america-banner.jpg" alt="">
      </div>
      <TopNavigation v-if="$store.state.user.loggedIn"/>
      <router-view :key="$route.path"/>
      <BottomNavigation v-if="$store.state.user.loggedIn"/>
      <div v-if="$store.state.user.loggedIn === false" class="footer">
        <div class="footer-nav">
          <router-link :to="{ name: 'ContentPages', params: { geo: $store.state.currentGeo, slug: 'terms'} }">
            {{ $store.state.globalContent.trTermsAndConditions }}
          </router-link>
          <router-link :to="{ name: 'ContentPages', params: { geo: $store.state.currentGeo, slug: 'privacy'} }">
            {{ $store.state.globalContent.trPrivacy }}
          </router-link>
          <router-link :to="{ name: 'ContentPages', params: { geo: $store.state.currentGeo, slug: 'cookies'} }">
            {{ $store.state.globalContent.trCookies }}
          </router-link>
          <a href="mailto:contact@predictions4u.com?subject=Prediction4U-Contact">{{ $store.state.globalContent.trContact }}</a>
          <router-link :to="{ name: 'ContentPages', params: { geo: $store.state.currentGeo, slug: 'prizes'} }">
            {{ $store.state.globalContent.trPrizes }}
          </router-link>
          <router-link :to="{ name: 'ContentPages', params: { geo: $store.state.currentGeo, slug: 'rules'} }">
            {{ $store.state.globalContent.trRules }}
          </router-link>
        </div>
      </div>
      <CookieBar v-if="$store.state.user.loggedIn === false"/>
    </div>
  </div>
</template>

<script>
import TopNavigation from '@/components/TopNavigation.vue';
import BottomNavigation from '@/components/BottomNavigation.vue';
import LoaderOverlay from '@/components/LoaderOverlay.vue';
import CookieBar from '@/components/CookieBar.vue';

export default {
  components: {
    TopNavigation,
    BottomNavigation,
    LoaderOverlay,
    CookieBar,
  },
};
</script>

<style lang="scss">
  .app-body {
    width: 500px;
    max-width: 100%;
    margin: 0 auto;
    min-height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;
  }
  .pattern-bg {
    background-image: url('assets/images/pattern-bg.jpg');
    background-size: cover;
  }
  .footer {
    padding: 0 1rem 1rem;
    margin-top: 1rem;
  }
  .top-bg-box {
    height: 150px;
    width: 100%;
    position: absolute;
    overflow: hidden;
    background-image: url('assets/images/predictor-bg.jpg');
    background-repeat: no-repeat;
    background-size: 100% 150px;
    img {
      display: block;
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }

  .footer-nav {
    font-size: 12px;
    text-align: center;
    a {
      color: $font;
      margin: .25rem;
      display: inline-block;
    }
  }
</style>
