import Vue from 'vue';
import firebase from 'firebase/app';
import 'firebase/auth';
import VueRouter from 'vue-router';
import store from '@/store';
import Login from '../views/Login.vue';
import PageNotFound from '../views/PageNotFound.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: '/uk/login',
  },
  {
    path: '/:geo/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/:geo/tournament/:tournament',
    name: 'Selections',
    meta: { requiresAuth: true },
    component: () => import('../views/Selections.vue'),
  },
  {
    path: '/:geo/tournament/:tournament/leaderboard',
    name: 'Leaderboard',
    meta: { requiresAuth: true },
    component: () => import('../views/Leaderboard.vue'),
  },
  {
    path: '/:geo/contents/:slug',
    name: 'ContentPages',
    component: () => import('../views/ContentPages.vue'),
  },
  {
    path: '*',
    name: 'PageNotFound',
    component: PageNotFound,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    document.getElementById('app').scrollIntoView();
  },
});

router.beforeEach((to, from, next) => {
  if (store.state.currentGeo === null) store.dispatch('setCurrentGeo', to.params.geo);
  if (store.state.appInitiated === false) store.dispatch('getGlobalContent', to.params.geo);
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (firebase.auth().currentUser) {
      next();
    } else {
      next({
        path: `/${to.params.geo}/login`,
      });
    }
  } else {
    next();
  }
});

export default router;
