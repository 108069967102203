const getParameterByName = (name, url = window.location.href) => {
  const nameClean = name.replace(/[[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${nameClean}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);
  if (!results) return false;
  if (!results[2]) return false;
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export default getParameterByName;
